import React from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';

import Home from "./pages/Home";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "rgb(255,255,255)",
    },
    secondary: {
      main: "rgb(150,100,100)",
    },
  }
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Home />
      </div>
    </ThemeProvider>
  );
};

export default App;
